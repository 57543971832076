const fileToBase64 = {
    // data: function () {
    //     return {
    //         fileDataUrl
    //     }
    // },
    methods:{
        fileChange (name){
            let fg = new FileReader();
            let fileInput = document.getElementById(name).files[0]
            fg.readAsDataURL(fileInput);
          
            setTimeout(() => {
                this.fileDataHandler = {
                    url: fg.result,
                    file: fileInput
                };
            }, 1000);
        }
    }
}

export default fileToBase64